import React from "react";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import './styles.css';
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMTable from "../../../components/table/TZMTable";
import moment from "moment";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import { Column } from "primereact/components/column/Column";
import { toCurrency, toCurrencyFormatterPrecision, toPercentPrecision } from "../../../components/common/TZMFormatter";
export class DetalheAlteracaoListaPreco extends React.Component {

	constructor(props) {
        super(props);        
		this.state = {
            alteracaoListaPreco: props.alteracaoListaPreco,
            params: {
                produto: { pk: {}, descricao: "" }
            },
            alterado: false,
			visible: true
		};
    }

	precaAnteriorTemplate = (data) => {
		return (
			<div className="margin-left-5">
				<div className="template-inclusao">
					<i className="tzm-big-icon fa fa-money-bill-alt margin-right-5" style={{color: '#259cac'}}/>
					<div>{ toCurrencyFormatterPrecision(data?.preco)}</div>
				</div>
			</div>
		)
	}
	novoPrecoTemplate = (data) => {
		return (
			<div className="margin-left-5">
				<div className="template-inclusao">
					<i className="tzm-big-icon fa fa-money-bill-alt margin-right-5" style={{color: '#045f10'}}/>
					<div>{ toCurrencyFormatterPrecision(data?.novoPreco)}</div>
				</div>
			</div>
		)
	}
	produtoTemplate = (data) => {
		return (
			<div className="margin-left-5">
				<div className="template-inclusao">
					<i className="tzm-big-icon fa fa-tags margin-right-5" style={{color: '#b6aa00'}}/>
					<div>{data?.produtoDescricao}</div>
				</div>
			</div>
		)
	}

	statusTemplate = (data) => {
		let status = [];			

		if(data.processado){
			status.push(<i title={`Item processado`} className="tzm-big-icon fa fa-check"  style={{color: '#039723'}} />);
		}else{
			status.push(<i title={`Item aguardando processamento`} className="tzm-big-icon fa fa-hourglass-half"  style={{color: '#6b068a'}} />);	
		}

		if(data.erro){
			status.push(<i title={`Item processado`} className="tzm-big-icon fa fa-exclamation-triangle" style={{color: '#dce92a'}} />);
		}

		return <div style={{ textAlign: "center" }}>{status}</div>;	
	}

	columns = [
		<Column 
			header="SKU"
			key="produtoId"
			field="produtoId"
			name="produtoId"
			style={{width: "15em"}}
		/>,

		<Column 
			header="Produto"
			key="produto"
			body={this.produtoTemplate}
		/>,

		<Column 
			sortable
			header="Preço Anterior"
			key="preco"
			field="preco"
			body={this.precaAnteriorTemplate}
			style={{width: "15em"}}
		/>,
		<Column 
		 	sortable
			header="Novo Preço"
			key="novoPreco"
			field="novoPreco"
			body={this.novoPrecoTemplate}
			style={{width: "15em"}}
		/>,
		<Column 
			header="Status"
			key="status"
			body={this.statusTemplate}
			style={{width: "10em"}}
		/>,
	];

	onHide = () => this.setState({ visible: false });
   
    render() {
		return (
			<TZMDialog style={{width: "1400px"}} visible={this.state.visible} modal header="Alteração Lista de Preço" onHide={this.onHide} {...this.props}>
				<TZMTabView 
					onTabChange={(event) => this.setState({ selectedTab: event.index })} 
					activeIndex={this.state.selectedTab}
				>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Dados Principais</span>}>
						<div className="ui-g">

							{
								this.props.alteracaoListaPreco.mensagemErro 
									? <div className="ui-g-12">
										<div className="tzm-error-container">
											<div className="tzm-font-2rem">
												<i className="tzm-big-icon fa fa-exclamation-triangle margin-right-5" style={{color: '#721c24'}}/>
												<strong className="margin-right-5 tzm-font-2rem">Erro:</strong>
												{this.props.alteracaoListaPreco.mensagemErro}
											</div>
										</div>
									</div>
									: null
							}
							{
								this.props.alteracaoListaPreco.canceladoPor 
									? <div className="ui-g-12">
										<div className="tzm-error-container">
											<div className="tzm-font-2rem">
												<i className="tzm-big-icon fa fa-times margin-right-5" style={{color: '#721c24'}}/>
												<strong className="margin-right-5 tzm-font-2rem">Cancelado:</strong>
												{`Esta alteração foi cancelada por "${this.props.alteracaoListaPreco.canceladoPor}" em ${moment(this.props.alteracaoListaPreco.canceladoEm).format("DD/MM/YYYY HH:mm:ss")}`}
											</div>
										</div>
									</div>
									: null
							}
							
							<div id="inclusao" className="ui-g-6">
								<label className="label-info" for='inclusao' id="inclusao">
									Inclusão:
								</label>
								<div className="tzm-container-data-flex">
									<div className="template-info">
										<i className="tzm-big-icon fa fa-calendar margin-right-5" style={{color: '#098810'}}/>
										<strong className="margin-right-5">Início: </strong>
										{`${moment(this.state.alteracaoListaPreco.inicioInclusao).format("DD/MM/YYYY HH:mm")}`}
									</div>
									{
										this.state.alteracaoListaPreco.terminoInclusao 
										? <div className="template-info">
											<i className="tzm-big-icon fa fa-calendar-check margin-right-5" style={{color: '#098810'}}/>
											<strong className="margin-right-5">Fim:</strong>
											{`${moment(this.state.alteracaoListaPreco.terminoInclusao).format("DD/MM/YYYY HH:mm")}`}
										</div>
										: null
									}
									<div className="template-info">
										<i className="tzm-big-icon fa fa-list margin-right-5" style={{color: '#098810'}}/>
										<strong className="margin-right-5">Quantidade: </strong>
										{`${this.state.alteracaoListaPreco.salvosInclusao} de ${this.state.alteracaoListaPreco.quantidadeInclusao}`}
									</div>
								</div>
								
							</div>		

							<div id="inclusao" className="ui-g-6">
								<label className="label-info">
									Processamento:
								</label>
								<div className="tzm-container-data-flex">
									{
										this.state.alteracaoListaPreco.inicioProcessamento
										? <div className="template-info">
											<i className="tzm-big-icon fa  fa-calendar margin-right-5" style={{color: '#8d1e0a'}}/>
											<strong className="margin-right-5">Início: </strong>
											{`${moment(this.state.alteracaoListaPreco.inicioProcessamento).format("DD/MM/YYYY HH:mm")}`}
										</div>
										: null
									}
									{
										this.state.alteracaoListaPreco.terminoProcessamento
											? <div className="template-info">
												<i className="tzm-big-icon fa  fa-calendar-check margin-right-5" style={{color: '#8d1e0a'}}/>
												<strong className="margin-right-5">Fim:</strong>
												{`${moment(this.state.alteracaoListaPreco.terminoProcessamento).format("DD/MM/YYYY HH:mm")}`}
											</div>
											: null
									}
									<div className="template-info">
										<i className="tzm-big-icon fa  fa-list margin-right-5" style={{color: '#8d1e0a'}}/>
										<strong className="margin-right-5">Quantidade: </strong>
										{`${this.state.alteracaoListaPreco.salvosProcessamento} de ${this.state.alteracaoListaPreco.quantidadeProcessamento}`}
									</div>
								</div>
								
							</div>		
							
							<div id="inclusao" className="ui-g-2">
								<label className="label-info">
									Vigência:
								</label>
								<div className="tzm-container-data-flex">
									<div className="template-info">
										<i className="fa fa-calendar-day margin-right-5 tzm-big-icon" style={{color: '#2500fa'}}/>
										{`${moment(this.state.alteracaoListaPreco.vigencia).format("DD/MM/YYYY")}`}
									</div>
								</div>
							</div>		
							<div id="inclusao" className="ui-g-2">
								<label className="label-info">
									Fator:
								</label>
								<div className="tzm-container-data-flex">
									<div className="template-info">
										{
											this.state.alteracaoListaPreco.fator === 'AUMENTO'
												? <>
													<i className="fa fa-long-arrow-alt-up margin-right-5 tzm-big-icon" style={{color: '#098810'}}/>
													<div>Aumento</div>
												</>
												: <>
													<i className="fa fa-long-arrow-alt-down margin-right-5 tzm-big-icon" style={{color: '#b61f1f'}}/>
													<div>Redução</div>
												</>
										}
										
									</div>
								</div>
							</div>		
							<div id="inclusao" className="ui-g-2">
								<label className="label-info">
									Forma:
								</label>
								<div className="tzm-container-data-flex">
									<div className="template-info">
										{
											this.state.alteracaoListaPreco.forma === 'PERCENTUAL'
												? <>
													<i className="fa fa-percentage margin-right-5 tzm-big-icon" style={{color: '#300286'}}/>
													<strong className="margin-right-5">Percentual: </strong>
													<div>{toPercentPrecision(this.state.alteracaoListaPreco.valor/100)}</div>
												</>
												: <>
													<i className="fa fa-dollar-sign margin-right-5 tzm-big-icon" style={{color: '#867d02'}}/>
													<strong className="margin-right-5">Valor: </strong>
													<div>{toCurrency(this.state.alteracaoListaPreco.valor)}</div>
												</>
										}
										
									</div>
								</div>
							</div>			

							<div id="inclusao" className="ui-g-2">
								<label className="label-info">
									Criado por:
								</label>
								<div className="tzm-container-data-flex">
									<div className="template-info">
										<i className="tzm-big-icon fa fa-user margin-right-5" style={{color: '#022586'}}/>
										{`${this.state.alteracaoListaPreco.criadoPor}`}
									</div>
								</div>
							</div>			

							<div id="inclusao" className="ui-g-6">
								<label className="label-info" for='inclusao' id="inclusao">
									Parâmetros utilizados:
								</label>
								<div className="tzm-container-data-flex">
									{
										this.state.alteracaoListaPreco.familias
											? <div className="template-info">
												<i className="tzm-big-icon fa fa-check margin-right-5" style={{color: '#880968'}}/>
												<strong className="margin-right-5">Famílias: </strong>
												{`${this.state.alteracaoListaPreco.familias}`}
											</div>
											: null
									}
									{
										this.state.alteracaoListaPreco.cliente
											? <div className="template-info">
												<i className="tzm-big-icon fa fa-check margin-right-5" style={{color: '#880968'}}/>
												<strong className="margin-right-5">Cliente: </strong>
												{`${this.state.alteracaoListaPreco.cliente}`}
											</div>
											: null
									}
									{
										this.state.alteracaoListaPreco.codigo
											? <div className="template-info">
												<i className="tzm-big-icon fa fa-check margin-right-5" style={{color: '#880968'}}/>
												<strong className="margin-right-5">Código: </strong>
												{`${this.state.alteracaoListaPreco.codigo}`}
											</div>
											: null
									}
									{
										this.state.alteracaoListaPreco.grupo
											? <div className="template-info">
												<i className="tzm-big-icon fa fa-check margin-right-5" style={{color: '#880968'}}/>
												<strong className="margin-right-5">Grupo: </strong>
												{`${this.state.alteracaoListaPreco.grupo}`}
											</div>
											: null
									}
								</div>
								
							</div>		

							<div id="inclusao" className="ui-g-6">
								<label className="label-info">
									Motivo:
								</label>
								<div className="tzm-container-data-flex">
									<div className="template-info">
										{`${this.state.alteracaoListaPreco.motivoReajuste ? this.state.alteracaoListaPreco.motivoReajuste : 'Nenhum motivo informado.'}`}
									</div>
								</div>
							</div>		
						</div>

					</TZMTabPanel>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Itens</span>}>
						<div className="ui-g">	
							<div className="ui-g-12">
								<TZMPanel header="Itens">
									<TZMTable 
										value={this.state.alteracaoListaPreco.itens} 
										paginator 
										rows={15} 
										// rowsPerPageOptions={[15, 2, 60]} 
										pageLinkSize={10} children={this.columns} 
									/>
								</TZMPanel>
							</div>
						</div>
					</TZMTabPanel>
				</TZMTabView>
								
				<TZMPanelFooter>
					<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-times" onClick={this.onHide} />
				</TZMPanelFooter>				
			</TZMDialog>
		);
	}

}
//&nbsp;