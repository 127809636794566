import moment from "moment";
import "moment/min/locales";
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMCalendar from "../../../components/common/TZMCalendar";
import {toDecimal} from "../../../components/common/TZMFormatter";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import {Fetch} from "../../../utilities/Fetch";
import TZMUserData from "../../../utilities/TZMUserData";
import {MetaService} from "../../servicos/MetaService";
import EditarMeta from "./EditarMeta";

const title = "Metas";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class Meta extends React.Component {

	constructor() {
		super();
		this.state = {
			list: [], 
			data: {},
			params: {
				periodo: [moment().startOf('year').format("YYYY-MM-DD"), moment().endOf('year').format("YYYY-MM-DD")]
			}
		};			
		this.metaService = new MetaService();
		moment.locale('pt-BR');
	}



	componentDidMount() {
		if (TZMUserData.isAutenticado()) {
			Fetch.Get("/ping");
		} else {
			window.location.replace("/");		
		}
	}

	mesFormat(rowData, column) {
		let mes = moment(rowData[column.field]).format("MMMM  [-] YYYY");
		mes = mes.charAt(0).toUpperCase() + mes.slice(1);
		return mes;
	} 

	handleList = () => {
		this.metaService.listar(this.state.params).then((data) => this.setState({list: data}));
	}	

	selecionar = (event) => {		
		if (event.data != null) {
			this.metaService.completar(event.data).then((meta) => {
				ModalRealm.showDialog(<EditarMeta meta={meta} onClose={this.handleList} onDelete={this.handleList} onSave={this.handleList} />);
			});
		}
	}

	criar = () => {	
		this.metaService.criar().then( (meta) => {		
			ModalRealm.showDialog(<EditarMeta meta={meta} onClose={this.handleList} onDelete={this.handleList} onSave={this.handleList} />);
		});	
	}

	toDecimal(rowData, column) {
		return toDecimal(rowData[column.field]);		
	}

	columns = [
		<TZMColumn sortable field="mes" key="mes" body={this.mesFormat} header="Mês/Ano" />,
		<TZMColumn sortable field="budget" body={this.toDecimal}  key="Budget" header="Budget" />,		
		<TZMColumn sortable field="forecast" body={this.toDecimal}  key="forecast" header="Forecast" />,
		<TZMColumn sortable field="metaLocal" body={this.toDecimal}  key="metaLocal" header="Meta Local" />,
		<TZMColumn sortable field="metaInter" body={this.toDecimal} key="metaRRE" header="Meta RRE/RRUY" />,
		<TZMColumn sortable field="sas" body={this.toDecimal} key="metaSAS" header="Meta SAS" />,
		<TZMColumn key="acoes" header="Ações" style={{width: "5em", textAlign: "center"}} body={(meta) => {
			return (
				<div>
					{TZMUserData.hasRole("____META_E") ? <span key={0} title="Editar Meta" className="fa fa-edit ui-tzm-icon" onClick={() => this.selecionar({data: meta})} /> : null}
					{TZMUserData.hasRole("____META_D") ? <span key={1} title="Remover Meta" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.deleteMeta({data: meta})} /> : null}
				</div>
			);
		}} />
	];

	deleteMeta = (meta) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.metaService.excluir(meta).then(this.handleList);
		}} question="Tem certeza de que deseja excluir esta meta?" />);
	}

	handleUpdateParams = (event, field, index) => {
		let params = this.state.params;
		params[field][index] = event.target.value;
		this.setState({ params });
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g-12">
					<TZMPanel header={title}>
						<div className="ui-g">
							<div className="ui-g-2">
								<TZMCalendar label="Período" placeholder="Inicial" onChange={(event) => this.handleUpdateParams(event, "periodo", 0)} value={this.state.params.periodo[0]} />
							</div>
							<div className="ui-g-2">
								<TZMCalendar label="&nbsp;" placeholder="Final" onChange={(event) => this.handleUpdateParams(event, "periodo", 1)} value={this.state.params.periodo[1]} />
							</div>
						</div>
						<TZMPanelFooter>
							{TZMUserData.hasRole("____META_C") ? <TZMButton success={true} icon="fas fa-plus" label="Nova Meta" onClick={this.criar} /> : null}
							<TZMButton primary={true} icon="fas fa-search" label="Procurar" onClick={this.handleList} />
						</TZMPanelFooter>
					</TZMPanel>
					<div style={{height: "8px"}} />
					<TZMPanel header="Resultado da Pesquisa">
						<TZMTable value={this.state.list} paginator rows={50} children={this.columns} />
					</TZMPanel>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}