import moment from 'moment';
import TZMDialog from '../../../components/dialog/TZMDialog';
import { ColumnGroup } from 'primereact/components/columngroup/ColumnGroup';
import { Row } from 'primereact/components/row/Row';
import React from 'react';
import { toCurrency, toDecimal } from '../../../components/common/TZMFormatter';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import TZMPanelFooter from '../../../components/panel/TZMPanelFooter';
import TZMButton from '../../../components/common/TZMButton';
import TZMShortcut from '../../../utilities/TZMShortcut';

export class PainelPCPDetalhe extends React.Component {

    constructor(props) {
        super(props);
        this.export = this.export.bind(this);
        moment.locale('pt-BR');
        this.state = {
            visible: true,
            listDetalhes: props.listDetalhes,
            status: props.status,
            header: props.header
        };
    }

    fechar = () => {
        this.setState({ visible: false });
    }

    mesFormat(rowData, column) {
        return moment(rowData[column.field]).format("DD/MM/YYYY");
    }

    currencyFormat(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toCurrency(rowData[column.field])}</div>;
    }


    toDecimal(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toDecimal(Math.round(rowData[column.field]))}</div>
    }

    pedido(rowData, column) {
        return <div style={{ textAlign: "left" }}>{`${rowData.pedidoId} -  ${rowData.itemId}`}</div>
    }

    export() {
        this.dt.exportCSV();
    }

    render() {
        return (
            <TZMDialog style={{ width: "99%" }} visible={this.state.visible} modal header={this.state.header} {...this.props}>
                <div className="ui-g">
                    <DataTable emptyMessage="Nenhum registro encontrado" ref={(el) => this.dt = el} value={this.state.listDetalhes}
                    
                        paginator={true} rows={15} pageLinkSize={10} footerColumnGroup={
                            <ColumnGroup>
                                <Row>
                                    <Column colSpan={3} style={{ fontWeight: "bold", fontSize: "12px", textAlign: "left" }} footer={`Total de Registros: ` + this.state.listDetalhes.length} />
                                    {this.state.status === "BLOQUEADO_DATA_MINIMA" ? <Column /> : null}
                                    <Column colSpan={8}/>
                                    {/* <Column colSpan={4} style={{ fontWeight: "bold", fontSize: "12px", textAlign: "right" }} footer="Total:" />
                                    {this.state.status === "FATURADO" || this.state.status === "DEVOLUCAO" ?
                                        <Column  style={{ fontWeight: "bold", fontSize: "12px", textAlign: "right" }} footer={toDecimal(Math.round(this.state.listDetalhes.map((item) => item.faturadoDia).reduce((a, b) => a + b, 0)))} />
                                        :
                                        <Column  style={{ fontWeight: "bold", fontSize: "12px", textAlign: "right" }} footer={toDecimal(Math.round(this.state.listDetalhes.map((item) => item.lancadoDia).reduce((a, b) => a + b, 0)))} />}
                                    <Column colSpan={6} /> */}
                                </Row>
                            </ColumnGroup>
                        }>
                        <Column style={{ width: "8em", textAlign: "center",   fontSize: "12px" }} sortable field="clienteId" key="clienteId" header="Cliente" filter={true} />
                        <Column style={{ width: "20em", textAlign: "left",   fontSize: "12px" }} sortable field="clienteRazaoSocial" key="clienteRazaoSocial" header="Razão Social" filter={true} />
                        <Column style={{ width: "8em", textAlign: "center",   fontSize: "12px" }} sortable field="pedidoId" key="pedidoId" header="Pedido" filter={true} body={this.pedido}/>                        
                        
                        <Column style={{ width: "6em", textAlign: "center", fontSize: "12px" }} sortable field="tipoMovimentoId" key="tipoMovimentoId" header="TM" filter={true} />    
                        <Column style={{ width: "6.5em", textAlign: "center",   fontSize: "12px" }} field="dataInclusao" key="dataInclusao" header="Data Inclusão" sort body={this.mesFormat} />
                        {this.state.status === "FATURADO" || this.state.status === "DEVOLUCAO" ?
                            <Column style={{ width: "6.5em", textAlign: "center",   fontSize: "12px" }} field="dataEmissao" key="dataEmissao" header="Data Emissão" sort body={this.mesFormat} /> :
                            <Column style={{ width: "6.5em", textAlign: "center",   fontSize: "12px" }} field="dataEntrega" key="dataEntrega" header="Data Entrega" sort body={this.mesFormat} />
                        }
                        {this.state.status === "BLOQUEADO_DATA_MINIMA" ?
                            <Column style={{ width: "6.5em", textAlign: "center",   fontSize: "12px" }} field="dataMinimaFaturamento" key="dataMinimaFaturamento" header="Data Mínima Faturamento" sort body={this.mesFormat} />
                            : null}
                        {this.state.status === "FATURADO" || this.state.status === "DEVOLUCAO" ?
                            <Column style={{ width: "5.5em", textAlign: "center",   fontSize: "12px" }} field="m2" key="m2" body={this.toDecimal} header="Faturado" />
                            :
                            <Column style={{ width: "5.5em", textAlign: "center",   fontSize: "12px" }} field="m2" key="m2" body={this.toDecimal} header="Vendido" />
                        }
                        <Column style={{ width: "4.5em", textAlign: "center", fontSize: "12px" }} sortable field="unidade" key="unidade" header="Unidade" filter={true} />
                        <Column style={{ width: "8em", textAlign: "center",   fontSize: "12px" }} sortable field="produtoId" key="produtoId" header="Cod. Produto" filter={true} />
                        <Column style={{ width: "25em", textAlign: "center",   fontSize: "12px" }} sortable field="produtoDescricao" key="produtoDescricao" header="Produto" filter={true} />
                        <Column style={{ width: "6em", textAlign: "center",   fontSize: "12px" }} sortable field="mercado" key="mercado" header="Mercado" filter={true} />
                    </DataTable>
                </div>
                <TZMPanelFooter>
                    <TZMButton className="ui-button-secondary"  label="Fechar" icon="fas fa-close" onClick={this.fechar} />
                    <TZMButton className="ui-button-info" style={{ float: "left" }} icon="fas fa-external-link-alt" label="CSV" onClick={this.export} />
                </TZMPanelFooter>
                <TZMShortcut active={this.state.visible} onEscape={this.fechar} />
            </TZMDialog>
        );
    }
}