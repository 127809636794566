import { httpDelete, httpGet, httpPost } from "../../utilities/TZMRequest";

export class MetaNovaService {

	completar(meta) {
		return httpGet(`/metas-nova/${meta.id}`);
	}

	listar(params) {
		return httpGet("/metas-nova", params);
	}

	salvar(meta) {
		return httpPost("/metas-nova", meta);
	}

	excluir(meta) {
		return httpDelete("/metas-nova", meta.id);
	}

	criar() {
		return httpGet("/metas-nova/criar-meta");
	}

}


