
import { httpGetAsync } from "../../../utilities/TZMRequest";

export class PainelGerencialAAPService {


    listar  = async (params) => {
        return httpGetAsync("/painel-gerencial-novo/listar", params);
    }

    atualizar  = async () => {
        return httpGetAsync("/painel-gerencial-novo/atualizar");
    }

    listarDetalhe  = async (params) => {
        return httpGetAsync("/painel-gerencial-novo/listarDetalhe", params);
    }
}