import { httpGet, httpGetAsync , httpPost} from "../../../utilities/TZMRequest";

export class PainelPcpService {

    salvarAcompanhamentoDiarioConfig  = async (data) => {        
        return httpPost("/acompanhamento-config/emails", data);
    }

    acompanhamentoDiarioConfig  = async (params) => {
        return httpGetAsync("/acompanhamento-config/emails/singleResult", params);
    }

    listar(params) {
		return httpGet("/painel-pcp/listar", {...params, sort: "data " });        
	}

}