import preval from 'preval.macro';
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/omega/theme.css";
import React from "react";
import { Switch } from "react-router";
import { Link, Route, withRouter } from "react-router-dom";
import "./App.css";
import TZMButton from "./components/common/TZMButton";
import TZMIcon from "./components/common/TZMIcon";
import TZMLogo from "./components/common/TZMLogo";
import TZMAppMenu from "./components/menubar/TZMAppMenu";
import TZMMenubar from "./components/menubar/TZMMenubar";
import { ModalRealm } from "./components/modal/ModalRealm";
import TZMSidebar from "./components/sidebar/TZMSidebar";
import TZMSidebarCard from "./components/sidebar/TZMSidebarCard";
import TZMSidebarCardFooter from "./components/sidebar/TZMSidebarCardFooter";
import { PainelVersao } from "./components/versao/PainelVersao";
import "./modules/font-awesome/css/fontawesome.css";
import "./modules/font-awesome/css/solid.css";
import Acompanhamento from "./pages/aap/acompanhamento/Acompanhamento";
import Inadimplencia from "./pages/aap/inadimplencia/Inadimplencia";
import PainelCAC from "./pages/aap/PainelCAC";
import PainelGerencialAAP from "./pages/aap/painelGerencial/PainelGerencialAAP";
import Pedido from "./pages/aap/pedido/Pedido";
import CheatSheet from "./pages/CheatSheet";
import Usuario from "./pages/comum//usuario/Usuario";
import Alcada from './pages/comum/alcada/Alcada';
import { Budget } from "./pages/comum/budget/Budget";
import { Cliente } from './pages/comum/cliente/Cliente';
import ContaEmail from './pages/comum/conta-email/ContaEmail';
import { Gestor } from "./pages/comum/gestor/Gestor";
import Meta from "./pages/comum/meta/Meta";
import { Perfil } from './pages/comum/perfil/Perfil';
import { Produto } from "./pages/comum/produto/Produto";
import { Regional } from "./pages/comum/regional/Regional";
import Configuracoes from "./pages/comum/usuario/Configuracoes";
import { Vendedor } from "./pages/comum/vendedor/Vendedor";
import { ListaPreco } from "./pages/comum/listapreco/ListaPreco"
import "./utilities/TZMFormat.js";
import TZMShortcut from "./utilities/TZMShortcut";
import TZMUserData from "./utilities/TZMUserData";
import { LogLantek } from './pages/comum/lantek/LogLantek';
import {Desconto} from "./pages/comum/desconto/Desconto";
import {DescontoEmail} from "./pages/comum/desconto/DescontoEmail";
import PainelAnaliseVendas from './pages/comum/painel-analise-vendas/PainelAnaliseVendas';
import { ListaPrecoAgendamento } from './pages/comum/listaprecoagendamento/ListaPrecoAgendamento.jsx';
import { Familia } from './pages/comum/familia/Familia.jsx';
import PainelCACNovo from './pages/paineis/cac/PainelCACNovo.js';
import PainelPCP from './pages/paineis/painelPcp/PainelPCP.js';
import MetaNova from './pages/comum/metaNova/MetaNova.js';

const ambiente = process.env.REACT_APP_AMBIENTE;
const BUILD_VERSION = preval`const moment = require('moment');module.exports = moment().format('YYYYMMDD-HHmm');`;

export const App = withRouter(class extends React.Component {

	state = {
		sidebarVisible: false,
		menus: [],
		allowed: {}
	};

	toggleSidebar = () => {
		this.setState({sidebarVisible: !this.state.sidebarVisible});
	}

	openSettings = () => {
		this.setState({sidebarVisible: false});
		ModalRealm.showDialog(<Configuracoes key={TZMUserData.getIdGenerator()} />);
	}

	runLogout = () => {
		TZMUserData.resetUsuario();
		window.location.replace("/");
	}

	showCheatSheet() {
		ModalRealm.showDialog(<CheatSheet key={TZMUserData.getIdGenerator()} />);
	}

	moveTo = (path) => {
		this.props.history.push(path);
		this.setState({sidebarVisible: false});
	}

	componentDidMount() {
		ModalRealm.showDialog(<PainelVersao key={TZMUserData.getIdGenerator()} />);
		let menus = [];
		let allowed = {
			"CAC": [],
			"GERENCIAL": [],
			"PCP": [],			
			"COMERCIAL": [],			
			"RELATORIOS": [],
			"PREÇOS": [],
			"CADASTROS": [],
		};
		
		if (ambiente === "INTERNO") {
			if (TZMUserData.hasRole("____USER_M")) {
				allowed["CADASTROS"].push("____USER_M");
				menus.push(<Route key={0} path="/usuarios" component={Usuario} />);
			}
			if (TZMUserData.hasRole("____PROD_M")) {
				allowed["CADASTROS"].push("____PROD_M");
				menus.push(<Route key={1} path="/produtos" component={Produto} />);
			}
			if (TZMUserData.hasRole("____GEST_M")) {
				allowed["CADASTROS"].push("____GEST_M");
				menus.push(<Route key={2} path="/gestores" component={Gestor} />);
			}
			if (TZMUserData.hasRole("____REGN_M")) {
				allowed["CADASTROS"].push("____REGN_M");
				menus.push(<Route key={3} path="/regionais" component={Regional} />);
			}
			if (TZMUserData.hasRole("____VEND_M")) {
				allowed["CADASTROS"].push("____VEND_M");
				menus.push(<Route key={4} path="/vendedores" component={Vendedor} />);
			}

			if (TZMUserData.hasRole("____FAMI_M")) {
			 	allowed["CADASTROS"].push("____FAMI_M");
			 	menus.push(<Route key={6} path="/familias" component={Familia} />);
			}

			if (TZMUserData.hasRole("____PERF_M")) {
				allowed["CADASTROS"].push("____PERF_M");
				menus.push(<Route key={7} path="/perfis" component={Perfil} />);
			}
			if (TZMUserData.hasRole("____CLIE_M")) {
				allowed["CADASTROS"].push("____CLIE_M");
				menus.push(<Route key={8} path="/clientes" component={Cliente} />);
			}
			if (TZMUserData.hasRole("____BUDG_M")) {
				allowed["CADASTROS"].push("____BUDG_M");
				menus.push(<Route key={10} path="/budgets" component={Budget} />);
			}
			if (TZMUserData.hasRole("____ALCD_M")) {
				allowed["CADASTROS"].push("____ALCD_M");
				menus.push(<Route key={11} path="/alcadas" component={Alcada} />);
			}
			if (TZMUserData.hasRole("____MAIL_M")) {
				allowed["CADASTROS"].push("____MAIL_M");
				menus.push(<Route key={12} path="/contas-email" component={ContaEmail} />);
			}
			if (TZMUserData.hasRole("____META_M")) {
				allowed["CADASTROS"].push("____META_M");
				menus.push(<Route key={7} path="/metas" component={Meta} />);
			}

			if (TZMUserData.hasRole("____META_NOVA_M")) {
				allowed["CADASTROS"].push("____META_NOVA_M");
				menus.push(<Route key={17} path="/metas-nova" component={MetaNova} />);
			}

			if (TZMUserData.hasRole("CAC_PCAC_M")) {
				allowed["CAC"].push("CAC_PCAC_M");
				menus.push(<Route key={8} path="/painelCAC" component={PainelCAC} />);				
			}
			if (TZMUserData.hasRole("CAC_PCAC_NOVO_M")) {
				allowed["CAC"].push("CAC_PCAC_NOVO_M");				
				menus.push(<Route key={99} path="/painel-cac-novo" component={PainelCACNovo} />);
			}

			if (TZMUserData.hasRole("PCP_ACPD_M")) {
				allowed["PCP"].push("PCP_ACPD_M");
				menus.push(<Route key={9} path="/acompanhamento" component={Acompanhamento} />);				
			}

			if (TZMUserData.hasRole("PCP_ACPD_NOVO_M")) {
				allowed["PCP"].push("PCP_ACPD_NOVO_M");				
				menus.push(<Route key={9999} path="/painel-pcp" component={PainelPCP} />);
			}


			if (TZMUserData.hasRole("PCP_LTEK_M")) {
				allowed["PCP"].push("PCP_LTEK_M");
				menus.push(<Route key={20} path="/lantek" component={LogLantek} />);
			}
			if (TZMUserData.hasRole("GER_AAP_PGER_M")) {
				allowed["GERENCIAL"].push("GER_AAP_PGER_M");
				menus.push(<Route key={15} path="/painelGerencialAAP" component={PainelGerencialAAP} />);
			}
			if (TZMUserData.hasRole("GER_AAP_DESC_M")) {
				allowed["GERENCIAL"].push("GER_AAP_DESC_M");
				menus.push(<Route key={16} path="/descontos" component={Desconto} />);
			}
			if (TZMUserData.hasRole("____LSTP_M")) {
				allowed["PREÇOS"].push("____LSTP_M");
				menus.push(<Route key={9} path="/listaspreco" component={ListaPreco} />);
			}

			if (TZMUserData.hasRole("____LSTPA_M")) {
				allowed["PREÇOS"].push("____LSTPA_M");
				menus.push(<Route key={9} path="/listas-preco-agendamento" component={ListaPrecoAgendamento} />);
			}

			if (TZMUserData.hasRole("GER_ANA_M")) {
				allowed["GERENCIAL"].push("GER_ANA_M");
				menus.push(<Route key={18} path="/painel-analise-vendas" component={PainelAnaliseVendas} />);
			}

			// if (TZMUserData.hasRole("REL_ACAR_M")) {
			// 	allowed["RELATORIOS"].push("REL_ACAR_M");
			// 	menus.push(<Route key={501} path="/relatorios" component={Relatorios} />);
			// }
			
		}

		if (TZMUserData.hasRole("GER_AAP_INAD_M")) {
			allowed["GERENCIAL"].push("GER_AAP_INAD_M");
			menus.push(<Route key={16} path="/inadimplencia" component={Inadimplencia} />);
		}

		if (TZMUserData.hasRole("CAC_PEDD_M")) {
			allowed["CAC"].push("CAC_PEDD_M");
			menus.push(<Route key={17} path="/pedidos" component={Pedido} />);
		}
		// Aprovacao de desconto por emailRo
		menus.push(<Route key={9999} path="/descontos-email" component={DescontoEmail} />);
		this.setState({menus, allowed});
	}

	links = {
		"CAC_PCAC_M": {priority: 1, label: "Painel CAC", to: "/painelCAC", icon: "th"},
		"CAC_PCAC_NOVO_M": {priority: 1, label: "Painel CAC (Novo)" , to: "/painel-cac-novo", icon: "th"},
		"CAC_PEDD_M": {priority: 2, label: "Pedidos", to: "/pedidos", icon: "file-alt"},
		"GER_AAP_INAD_M": {priority: 3, label: "Inadimplência", to: "/inadimplencia", icon: "hand-holding-usd"},
		"GER_ANA_M": 	{priority: 8, label: "Análise de Vendas", to: "/painel-analise-vendas", icon: "balance-scale"},
		"GER_AAP_PGER_M": {priority: 4, label: "Painel Gerencial", to: "/painelGerencialAAP", icon: "money-check-alt"},										
		"PCP_ACPD_M": {priority: 9, label: "Acompanhamento Diário", to: "/acompanhamento", icon: "money-check-alt"},		
		"PCP_ACPD_NOVO_M": {priority: 10, label: "Acompanhamento Diário (Novo)", to: "/painel-pcp", icon: "money-check-alt"},
		"PCP_LTEK_M": {priority: 20, label: "Lantek", to: "/lantek", icon: "database"},		
		"____PROD_M": {priority: 401, label: "Produtos", to: "/produtos", icon: "tags"},		
		"____META_M": {priority: 400, label: "Metas", to: "/metas", icon: "chart-line"},
		"____META_NOVA_M": {priority: 401, label: "Metas (Nova)", to: "/metas-nova", icon: "chart-line"},
		"____MAIL_M": {priority: 402, label: "Contas de E-mail", to: "/contas-email", icon: "envelope"},
		"____GEST_M": {priority: 403, label: "Gestores", to: "/gestores", icon: "users"},
		"____REGN_M": {priority: 404, label: "Regionais", to: "/regionais", icon: "globe"},		
		"____VEND_M": {priority: 405, label: "Vendedores", to: "/vendedores", icon: "user-tag"},
		"____PERF_M": {priority: 407, label: "Perfis", to: "/perfis", icon: "th"},
		"____BUDG_M": {priority: 406, label: "Budgets", to: "/budgets", icon: "user-tag"},		
		"____ALCD_M": {priority: 408, label: "Alçadas", to: "/alcadas", icon: "percent"},
		 "____FAMI_M": {priority: 408, label: "Familías", to: "/familias", icon: "align-justify"},
		"____USER_M": {priority: 409, label: "Usuários", to: "/usuarios", icon: "user-circle"},
		"____CLIE_M": {priority: 410, label: "Clientes", to: "/clientes", icon: "user-tie"},		
		"____LSTP_M": {priority: 411, label: "Lista de Preço", to: "/listaspreco", icon: "file-invoice-dollar"},		
		"____LSTPA_M": {priority: 412, label: "Agendamentos", to: "/listas-preco-agendamento", icon: "business-time"},		
		"GER_AAP_DESC_M": {priority: 7, label: "Gestão de Preços", to: "/descontos", icon: "percentage"}
		// ,"REL_ACAR_M": {priority: 501, label: "Relatórios", to: "/relatorios", icon: "file-pdf"},
	};

	linkTemplate = (index, linkData) => {
		return {
			index: linkData.priority,
			value: (
				<div key={TZMUserData.getIdGenerator()}> 
					<Link key={linkData.to} onClick={this.toggleSidebar} to={linkData.to}>
						<div key={index} className="ui-sidebar-link">
							<TZMIcon className="sidebar-link" name={linkData.icon} />
							<span>{linkData.label}</span>
						</div>
					</Link>
				</div>
			)
		};
	}
	
	renderGroupLinks = (k) => {
		let i = 0;
		if (this.state.allowed[k].length) {
			return (
				<div key={TZMUserData.getIdGenerator()}>
					<div className="sidebar-link-header" style={{fontWeight: 'bold'}}>{k}</div>
					{this.state.allowed[k].map(b => this.linkTemplate(i++, this.links[b])).sort((a, b) => a.priority - b.priority).map((i) => i.value)}
				</div>
			);
		} else {
			return null;
		}
	}

	render() {		
		return (
			<div>
				<div className="ui-trademark"><span style={{marginRight: "4px"}}>BeOnTag {`1.0 ` + BUILD_VERSION}</span><TZMIcon name="copyright" /><span style={{marginLeft: "4px"}}>2022 TZM</span></div>
				<TZMMenubar>
					<TZMAppMenu active={this.state.sidebarVisible ? this.state.sidebarVisible  : undefined} onClick={this.toggleSidebar} />
					<div className="ui-tzm-logo" />
					<TZMLogo />
				</TZMMenubar>
				<TZMSidebar visible={this.state.sidebarVisible} onHide={() => this.setState({ sidebarVisible: false })}>
					<TZMSidebarCard>		
						<TZMSidebarCardFooter>
							<div className="ui-sidebar-footer-strip">
							<span>{TZMUserData.getPrincipal().nome}</span>
								<TZMButton className="sidebar-button ui-button-danger"  icon="fas fa-sign-out-alt" title="Sair do Sistema" onClick={this.runLogout} />
								<TZMButton className="sidebar-button ui-button-primary" icon="fas fa-question-circle" title="Tabela de Atalhos" onClick={this.showCheatSheet} />
								<TZMButton className="sidebar-button ui-button-success" icon="fas fa-cogs" title="Configurações" onClick={this.openSettings} />
							</div>
						</TZMSidebarCardFooter>
					</TZMSidebarCard>
					{Object.keys(this.state.allowed).map(this.renderGroupLinks)}
				</TZMSidebar>
				<main style={{margin: "10px"}}><Switch>{this.state.menus}</Switch></main>
				<TZMShortcut active master onCtrlO={this.openSettings} onF1={this.toggleSidebar} onCtrlF1={this.showCheatSheet} onCtrlQ={this.runLogout} />
				<ModalRealm />
				{window.location.pathname !== "/painelCAC" ? <div className="ui-trademark-placeholder" /> : null}
			</div>
		);
	}

});
