import moment from 'moment';
import React from 'react';
import { Column } from 'primereact/components/column/Column';
import TZMMainContent from '../../../components/main/TZMMainContent';
import TZMPanel from '../../../components/panel/TZMPanel';
import TZMForm from '../../../components/common/TZMForm';
import TZMCalendar from '../../../components/common/TZMCalendar';
import { Button } from 'primereact/components/button/Button';
import TZMTabView from '../../../components/panel/TZMTabView';
import TZMTabPanel from '../../../components/panel/TZMTabPanel';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { ColumnGroup } from 'primereact/components/columngroup/ColumnGroup';
import { Row } from 'primereact/components/row/Row';
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RelatorioService } from '../../servicos/RelatorioService';
import TZMUserData from '../../../utilities/TZMUserData';
import { toDate, toDecimal, toPercent } from '../../../components/common/TZMFormatter';
import { ModalRealm } from '../../../components/modal/ModalRealm';
import {PainelPCPDetalheProduzidoProduzir } from './PainelPCPDetalheProduzidoProduzir';
import { PainelPCPDetalhe } from './PainelPCPDetalhe';
import { Validator } from '../../../utilities/TZMValidator';
import TZMConfirm from '../../../components/dialog/TZMConfirm';
import TZMTextField from '../../../components/common/TZMTextField';
import TZMButton from '../../../components/common/TZMButton';
import TZMTextFieldPainel from '../../../components/common/TZMTextFieldPainel';
import { Progressbar } from '../../../components/progressbar/Progressbar';
import './PainelPCP.css'
import { PainelPcpService } from '../servicos/PainelPcpService';
const title = "Acompanhamento Diário";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class PainelPCP extends React.Component { 

    constructor(props) {

        super(props);
        moment.locale('pt-BR');
        this.state = {    
            diarios: [],  
            familias: [],
            familiasCategoria: [],
            grafico:[],
            meta:{

                forecast:0,
                forecastFaturar: 0,
                percentualForecastFaturado: 0,
                forecastVender: 0,
                percentualForecastVendido: 0,
                totalGeral: 0,

                budget: 0,
                budgetFaturar: 0,
                percentualBudgetFaturado: 0,
                budgetVender: 0,
                percentualBudgetVendido: 0,

                nacional: 0,
                nacionalFaturar: 0,
                percentualNacionalFaturado: 0,
                nacionalVender: 0,
                percentualNacionalVendido: 0,
                totalNacional: 0,

                intercompany: 0,
                intercompanyFaturar: 0,
                percentualIntercompanyFaturado: 0,
                intercompanyVender: 0,
                percentualIntercompanyVendido: 0,
                totalIntercompany: 0,

                exportacao:0,
                exportacaoFaturar: 0,
                percentualExportacaoFaturado: 0,
                exportacaoVender: 0,
                percentualExportacaoVendido: 0,
                totalExportacao: 0,

                officePimaco: 0,
                officePimacoFaturar: 0,
                percentualOfficePimacoFaturado: 0,
                officePimacoVender: 0, 
                percentualOfficePimacoVendido: 0,
                totalOfficePimaco: 0

            },
            
            activeIndex: 0,
            email: '',              
            loadingVendido: false,
            loadingAcompanhamento: false,
            loading: false,
            mediaFaturamentoDia: 0,
            mediaVendaDia: 0,
            params: this.defaultParams,
            mes: moment()
        };
        this.headerTemplate = this.headerTemplate.bind(this);
        this.footerTemplate = this.footerTemplate.bind(this);                
        this.toDecimalClickFooter = this.toDecimalClickFooter.bind(this);        
        this.footerColumns = this.footerColumns.bind(this);
        this.relatorioService = new RelatorioService();
        this.painelPcpService = new PainelPcpService();
    }   
    
    componentDidMount() {
        if (TZMUserData.isAutenticado()) {
            this.resizeGraphs();
            window.addEventListener("resize", this.resizeGraphs);
        }        
    }
    
    defaultParams = {
        data: this.rangeDiario()
    };  
    
    changeTab = (event) => {
        this.setState({ activeIndex: event.index })
    }

    rangeDiario() {
        let date = new Date();
        let firstDay = moment(date).format("YYYY-MM-DD");
        return [firstDay];
    }

    handleList = () => {
        this.painelPcpService.listar({ data: this.state.params.data[0] }).then((response) => {            
            let diarios = response.diarios;
            let familias = response.familias;
            let familiasCategoria = response.familiasCategoria;
            let mediaFaturamentoDia = response.mediaFaturamentoDia;
            let mediaVendaDia = response.mediaVendaDia;
            let meta = response.meta;
            let grafico =  diarios?.filter(a => moment(a.data) <= moment() && (a.faturado > 0 || a.vendido > 0)).map(this.criarListaGrafico);       
            this.setState({
                mes: this.state.params.data[0], diarios, mediaFaturamentoDia, mediaVendaDia, familias,familiasCategoria, grafico, meta
            });
        });
    }

    relatorioAcompanhamentoVendidoXFaturado = async () => {
        this.setState({ loading: true, loadingVendido: true })
        await this.relatorioService.relatorioPainelPcpVendidoXFaturado({ data: this.state.params.data[0] }).then(response => {
            this.setState({ loading: false, loadingVendido: false })
        });
    }

    relatorioAcompanhamentoConsolidado = async () => {
        this.setState({ loading: true, loadingVendido: true })
        await this.relatorioService.relatorioPainelPcpConsolidado({ data: this.state.params.data[0] }).then(response => {
            this.setState({ loading: false, loadingVendido: false })
        });
    }

    criarListaGrafico(value, index, array) {       
        
        let dia = value["dia"];
        let data = value["data"];
        let vendido = value["vendido"];        
        let faturado = value["faturado"];               
        return {dia,vendido,faturado,data};        
    }

    status = (rowData) => {
        switch (rowData) {
            case "VENDIDO":
                return "Vendido";
            case "BLOQUEADO":
                return "Bloqueado";
            case "CARTEIRA":
                return "Carteira";
            case "FATURADO":
                return "Faturado";
            case "DEVOLUCAO":
                return "Devolução";
            case "AFATURAR":
                return "A Faturar";
            case "BLOQUEADO_DATA_MINIMA":
                return "Bloqueado por Data Mínima ";
            case "VOLUME_LIBERADO":
                return "Volume Liberado";   
            case "PRODUZIDO":
                return "Produzido";
            case "PRODUZIR":
                return "Produzir";
            default:
                return "Não Identificado ";
        }
    }

    resizeGraphs = () => {
        this.setState({windowSize: window.innerHeight - 94});
    }

    handleUpdateParams = (event, field, index) => {
        let params = this.state.params;
        params[field][index] = event.target.value;
        this.setState({ params });
    }

    dateFormat(rowData, column) {
        return toDate(rowData[column.field]);
    }

    toDecimal(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toDecimal(Math.round(rowData[column.field]))}</div>
    }

    toPercent(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toPercent(rowData[column.field])}</div>
    }

    mesFormat(rowData, column) {
        console.log(column)
        return moment(rowData[column.field]).format("DD [-] dddd");
    }

    toDecimalClick(rowData, column, status, lista, title) {

        let valor = toDecimal(Math.round(rowData[column.field]));

        return <div style={{ textAlign: "right" }}>
            <span style={{ cursor: "pointer" }} title="Detalhar" onClick={
                () =>   this.detalhes(lista, status, title + " - " + this.status(status) + " - " + valor + "(m²)"  )
            } >
                {valor}
            </span>
        </div>;
    }

    toDecimalClickFooter(lista, column, status, title) {
        
        let valor = toDecimal(Math.round(lista?.map((item) => item[column]).reduce((a, b) => a + b, 0)));
        return <div style={{ textAlign: "right" }}>
            {lista !== null && lista.length > 0 ?
                <span 
                    style={{ fontSize: "14px", cursor: "pointer" }} 
                    title="Detalhar" 
                    onClick={() => this.handleListDetalhes(lista, column, status, `Total ${title} - ${valor} (m²)`)} 
                >
                    {valor}
                </span>
                :
                <span style={{ fontSize: "14px" }}>{0}</span>}
        </div>;
    } 

    detalhes = (lista, status, header) => {
        if (status === 'PRODUZIDO' || status === 'PRODUZIR') {
            ModalRealm.showDialog(<PainelPCPDetalheProduzidoProduzir key={TZMUserData.getIdGenerator()} header={header} listDetalhes={lista} status={status} />);
        } else {
            ModalRealm.showDialog(<PainelPCPDetalhe key={TZMUserData.getIdGenerator()} header={header} listDetalhes={lista} status={status} />);
        }
    }

    handleListDetalhes(lista, column, status, header) {
        const listaNome = this.columnToList(column)
        const listaFlat = lista.map((diario) => diario[listaNome]).flat();

        if (status === 'PRODUZIDO' || status === 'PRODUZIR') {
            ModalRealm.showDialog(<PainelPCPDetalheProduzidoProduzir key={TZMUserData.getIdGenerator()} header={header} listDetalhes={listaFlat} status={status} />);
        } else {
            ModalRealm.showDialog(<PainelPCPDetalhe key={TZMUserData.getIdGenerator()} header={header} listDetalhes={listaFlat} status={status} />);
        }
    }

    columnToList(column) {
        switch (column) {
            case 'bloqueadoDataMinima':
                return 'bloqueadosDataMinima';
            case 'faturado':
                return 'faturados';
            case 'faturar':
                return 'faturars';
            case 'vendido':
                return 'vendidos';
            case 'volumeLiberadoFaturar':
                return 'volumesLiberados';
            case 'carteira':
                return 'carteiras';
            case 'bloqueado':
                return 'bloqueados';
            case 'produzido':
                return 'produzidos';
            case 'produzir':
                return 'produzirs';
            case 'devolucao':
                return 'devolucoes';
            case 'produzir':
                return 'produzirs';

        }
    }

    columnsDiario = [
        <Column 
            sortable 
            field="data" 
            key="data" 
            body={this.mesFormat} 
            header="Dia" 
            style={{ padding: '2px' }}
        />,
        <Column 
            field="vendido" 
            key="vendido" 
            body={(rowData, column) => this.toDecimalClick(rowData, column, 'VENDIDO', rowData.vendidos, moment(rowData?.data).format("DD/MM/YYYY"))} 
            style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center", padding: '2px' }} 
            header="Vendido (m²)" 
        />,
        <Column 
            field="faturado" 
            key="faturado" 
            body={(rowData, column) => this.toDecimalClick(rowData, column, 'FATURADO',rowData.faturados, moment(rowData?.data).format("DD/MM/YYYY"))} 
            style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} 
            header="Faturado (m²)" 
        />,
        <Column 
            field="faturar" 
            key="faturar" 
            body={(rowData, column) => this.toDecimalClick(rowData, column, 'AFATURAR',rowData.faturars, moment(rowData?.data).format("DD/MM/YYYY"))} 
            style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} 
            header="A Faturar (Liberado + Bloqueado) (m²)" 
        />,
        <Column 
            field="bloqueadoDataMinima" 
            key="bloqueadoDataMinima" 
            body={(rowData, column) => this.toDecimalClick(rowData, column, 'BLOQUEADO_DATA_MINIMA',rowData.bloqueadosDataMinima, moment(rowData?.data).format("DD/MM/YYYY"))} 
            style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} 
            header="Bloqueado Data Mínima (m²)" 
        />,
        <Column 
            field="volumeLiberadoFaturar" 
            key="volumeLiberadoFaturar" 
            body={(rowData, column) => this.toDecimalClick(rowData, column, 'VOLUME_LIBERADO',rowData.volumesLiberados, moment(rowData?.data).format("DD/MM/YYYY"))} 
            style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} 
            header="Volume liberado a faturar (m²)" 
        />
    ];

    columnsFamilias =  [
        <Column key="display" style={{width: "2em", textAlign: "center"}}/>,
        <Column field="familiaDescricao" style={{fontWeight: "bold",  textAlign: "center"}} key="familiaDescricao" header="Familia" />,
        <Column field="faturado" key="faturado" body={(rowData, column) => this.toDecimalClick(rowData, column, 'FATURADO', rowData.faturados, rowData.familiaDescricao)} style={{ fontWeight: "bold",  textAlign: "center" }} header="Faturado (m²)" />,
        <Column field="carteira" key="carteira" body={(rowData, column) => this.toDecimalClick(rowData, column, 'CARTEIRA',rowData.carteiras, rowData.familiaDescricao)} style={{ fontWeight: "bold", textAlign: "center" }} header="Carteira Liberado (m²)" />,
        <Column field="bloqueado" key="bloqueado" body={(rowData, column) => this.toDecimalClick(rowData, column, 'BLOQUEADO',rowData.bloqueados, rowData.familiaDescricao)} style={{ fontWeight: "bold", textAlign: "center" }} header="Carteira Bloqueado (m²)" />,        
        <Column field="produzido" key="produzido"  body={(rowData, column) => this.toDecimalClick(rowData, column, 'PRODUZIDO',rowData.produzidos, rowData.familiaDescricao)} style={{ fontWeight: "bold",  textAlign: "center" }} header="Produzido (m²)" />,
        <Column field="produzir" key="produzir"  body={(rowData, column) => this.toDecimalClick(rowData, column, 'PRODUZIR',rowData.produzirs, rowData.familiaDescricao)} style={{ fontWeight: "bold", textAlign: "center" }} header="Produzir (m²)" />,
        <Column field="devolucao" key="devolucao" body={(rowData, column) => this.toDecimalClick(rowData, column, 'DEVOLUCAO',rowData.devolucoes, rowData.familiaDescricao)} style={{ fontWeight: "bold",  textAlign: "center" }} header="Devolução (m²)" />,
        <Column field="total" key="total" body={this.toDecimal} style={{ fontWeight: "bold",  textAlign: "center" }} header="Total Faturado + Carteira" />
    ];
 
    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    compararNumeros = (a, b) => {
        return a - b;
    }

    headerTemplate(data) {
        return <div className={`painl-pcp-rowgroup-header-title ${data.categoriaCliente.toLowerCase()}`}style={{ fontSize: "16px", color: "#FFFFFF",  }}>{data.categoriaClienteExtenso}</div>
    }
    
    footerTemplate(data) {
        let lista = this.state?.familiasCategoria.filter(a => a.categoriaCliente === data.categoriaCliente);
        return ([   
                <td colSpan={2} key={data.categoriaClienteExtenso + '_footerTotalLabel'}  style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} > Total {data.categoriaClienteExtenso}</td>,
                <td  key="faturado" style={{ fontWeight: "bold", textAlign: "right" }}>
                    {this.toDecimalClickFooter(lista, "faturado", "FATURADO", "Faturado" + " " + data.categoriaClienteExtenso)}
                </td>,
                <td  key="carteira" style={{ fontWeight: "bold", textAlign: "right" }}>
                    {this.toDecimalClickFooter(lista, "carteira", "CARTEIRA", "Carteira" + " " + data.categoriaClienteExtenso)}                        
                </td>,
                <td  key="bloqueado" style={{ fontWeight: "bold", textAlign: "right" }}>
                    {this.toDecimalClickFooter(lista, "bloqueado", "BLOQUEADO", "Bloqueado" + " " + data.categoriaClienteExtenso)}
                </td>,
                <td  key="produzido" style={{ fontWeight: "bold", textAlign: "right", fontSize: "14px" }}>  
                    {this.toDecimalClickFooter(lista, "produzido", "PRODUZIDO", "Produzido" + " " + data.categoriaClienteExtenso)}                    
                </td>,
                <td  key="produzir" style={{ fontWeight: "bold", textAlign: "right", fontSize: "14px" }}>
                    {this.toDecimalClickFooter(lista, "produzir", "PRODUZIR", "Produzir" + " " + data.categoriaClienteExtenso)}                    
                </td>,
                <td  key="devolucao" style={{ fontWeight: "bold", textAlign: "right" }}>
                    {this.toDecimalClickFooter(lista, "devolucao", "DEVOLUCAO", "Devolução" + " " + data.categoriaClienteExtenso)}                        
                </td>,
                <td  key="total" style={{ fontWeight: "bold", textAlign: "right", fontSize: "14px" }}>
                    {toDecimal(Math.round(lista.map((item) => item.total).reduce((a, b) => a + b, 0)))}
                </td>
            ]
        );
    }

    footerColumns(list) {
        return [
            <Column key={0} colSpan={2} style={{ fontWeight: "bold", fontSize: "14px", textAlign: "Center" }} footer="Total Geral" /> ,
            <Column key={1} style={{ fontWeight: "bold", textAlign: "right",fontSize: "14px"  }} footer={this.toDecimalClickFooter(list, "faturado", "FATURADO", "Faturado")} />,
            <Column key={2} style={{ fontWeight: "bold", textAlign: "right",fontSize: "14px"  }} footer={this.toDecimalClickFooter(list, "carteira", "CARTEIRA", "Carteira")} />,
            <Column key={3} style={{ fontWeight: "bold", textAlign: "right",fontSize: "14px"  }} footer={this.toDecimalClickFooter(list, "bloqueado", "BLOQUEADO", "Bloqueado")} />,            
            <Column key={4} style={{ fontWeight: "bold", textAlign: "right", fontSize: "14px" }} footer={this.toDecimalClickFooter(list, "produzido", "PRODUZIDO", "Produzido")} />, 
            <Column key={5} style={{ fontWeight: "bold", textAlign: "right", fontSize: "14px" }} footer={this.toDecimalClickFooter(list, "produzir", "PRODUZIR", "Produzir")} />, 
            <Column key={6} style={{ fontWeight: "bold", textAlign: "right",fontSize: "14px"  }} footer={this.toDecimalClickFooter(list, "devolucao", "DEVOLUCAO", "Devolução")} />,
            <Column key={7} style={{ fontWeight: "bold", textAlign: "right",fontSize: "14px"  }} footer={toDecimal(Math.round(list.map((item) => item.total).reduce((a, b) => a + b, 0)))} />
        ];
    }

    render() {        
        return (
            <TZMMainContent>
                <div className="ui-g">
                    <div className="ui-g-9">
                        <TZMPanel header={title}>
                            <TZMForm>
                                <div className="ui-g">
                                    <div className="ui-g-1">
                                        <TZMCalendar label="Mês" dateFormat="mm/yy" onChange={(event) => this.handleUpdateParams(event, "data", 0)} value={this.state.params.data[0]} />
                                    </div>
                                    <div style={{ padding: "12px" }} className="ui-g-11">
                                        <Button icon="fa fa-search" label="Procurar" disabled={this.state.loading} className="ui-button-primary" onClick={this.handleList} />
                                        <Button icon={this.state.loadingVendido ? `fa fa-spin fa-circle-notch` : `fa fa-file-pdf`} disabled={this.state.loading} label="Vendido X Faturado" className="ui-button-success" onClick={this.relatorioAcompanhamentoVendidoXFaturado} />
                                        <Button icon={this.state.loadingAcompanhamento ? `fa fa-spin fa-circle-notch` : `fa fa-file-pdf`} disabled={this.state.loading} label="Consolidado" className="ui-button-success" onClick={this.relatorioAcompanhamentoConsolidado} />
                                    </div>
                                </div>
                                <TZMTabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.changeTab(e)}>                                
                                    <TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Diário</span>}>
                                        <div className="ui-g">
                                            <div className="ui-graphic-label-consolidado">Vendido x Faturado</div>
                                            <DataTable emptyMessage="Nenhum registro encontrado" ref={(el) => this.dt = el} value={this.state.diarios}
                                                footerColumnGroup={
                                                    <ColumnGroup>
                                                        <Row>
                                                            <Column style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} footer="TOTAL" />                                                            
                                                            <Column style={{ fontWeight: "bold" }} footer={this.toDecimalClickFooter(this.state.diarios, "vendido", "VENDIDO", "Vendido")} />
                                                            <Column style={{ fontWeight: "bold" }} footer={this.toDecimalClickFooter(this.state.diarios, "faturado", "FATURADO", "Faturado")} />
                                                            <Column style={{ fontWeight: "bold" }} footer={this.toDecimalClickFooter(this.state.diarios, "faturar", "AFATURAR", "A faturar")} />
                                                            <Column style={{ fontWeight: "bold" }} footer={this.toDecimalClickFooter(this.state.diarios, "bloqueadoDataMinima", "BLOQUEADO_DATA_MINIMA", "Bloqueado data mínima")} />
                                                            <Column style={{ fontWeight: "bold" }} footer={this.toDecimalClickFooter(this.state.diarios, "volumeLiberadoFaturar", "VOLUME_LIBERADO", "Volume liberado a faturar")} />
                                                        </Row>
                                                        <Row>
                                                            <Column style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} footer="Média/Dia" />
                                                            <Column style={{ fontWeight: "bold", fontSize: "14px", textAlign: "right" }} footer={toDecimal(Math.round(this.state.mediaVendaDia))} />
                                                            <Column style={{ fontWeight: "bold", fontSize: "14px", textAlign: "right" }} footer={toDecimal(Math.round(this.state.mediaFaturamentoDia))} />
                                                            <Column colSpan={3} />
                                                        </Row>
                                                    </ColumnGroup>
                                                }>
                                                {this.columnsDiario}
                                            </DataTable>
                                        </div>
                                    </TZMTabPanel>
                                    <TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Consolidado Categoria</span>}>
                                        <DataTable  
                                            emptyMessage="Nenhum registro encontrado" 
                                            value={this.state.familiasCategoria} 
                                            rowGroupMode="subheader"
                                            groupField="categoriaCliente"
                                            sortField="categoriaClienteOrdem"
                                            sortOrder={1}
                                            rowGroupHeaderTemplate={this.headerTemplate}
                                            rowGroupFooterTemplate={this.footerTemplate}
                                                    footerColumnGroup={
                                                        <ColumnGroup>
                                                            <Row>
                                                                <Column key="footer" colSpan={9} style={{ opacity: "0" }} footer={"T"}/> 
                                                            </Row> 
                                                            <Row>{this.footerColumns(this.state.familiasCategoria)}</Row>
                                                            <Row>                                                                
                                                                <Column key={0} colSpan={2} style={{ fontWeight: "bold", fontSize: "12px", textAlign: "Center" }} footer="Total (Faturado - Devolução)  " />                                                                     
                                                                <Column key={1} style={{ fontWeight: "bold", textAlign: "right",fontSize: "14px"  }} footer={toDecimal(Math.round(this.state.familiasCategoria.map((item) => (item.faturado-item.devolucao)).reduce((a, b) => a + b, 0)))} />
                                                                <Column key={2} colSpan={6} style={{ fontWeight: "bold", fontSize: "14px", textAlign: "Center" }}  />
                                                            </Row>
                                                            
                                                        </ColumnGroup>}>           
                                            {this.columnsFamilias}
                                        </DataTable>
                                    </TZMTabPanel>
                                    <TZMTabPanel header={<span><span className="ui-tab-number" children="3" />Consolidado Família</span>}>
                                      <DataTable  
                                        emptyMessage="Nenhum registro encontrado" 
                                        value={this.state.familias}
                                        rowGroupHeaderTemplate={this.headerTemplate}
                                        rowGroupFooterTemplate={this.footerTemplate}
                                        footerColumnGroup={
                                            <ColumnGroup>
                                                <Row>
                                                    <Column key="footer" colSpan={9} style={{ opacity: "0" }} footer={"T"}/> 
                                                </Row> 
                                                <Row>{this.footerColumns(this.state.familias)}</Row>
                                                <Row>                                                                
                                                    <Column key={0} colSpan={2} style={{ fontWeight: "bold", fontSize: "12px", textAlign: "Center" }} footer="Total (Faturado - Devolução)  " />                                                                     
                                                    <Column key={1} style={{ fontWeight: "bold", textAlign: "right",fontSize: "14px"  }} footer={toDecimal(Math.round(this.state.familias.map((item) => (item.faturado-item.devolucao)).reduce((a, b) => a + b, 0)))} />
                                                    <Column key={2} colSpan={6} style={{ fontWeight: "bold", fontSize: "14px", textAlign: "Center" }}  />
                                                </Row>
                                            </ColumnGroup>
                                        }
                                >           
                                            {this.columnsFamilias}
                                        </DataTable>

                                    </TZMTabPanel>
                                    <TZMTabPanel header={<span><span className="ui-tab-number" children="4" />Gráfico</span>}>
                                        <div className="ui-graphic-label" style={{ fontWeight: "bold", fontSize: "16px", textAlign: "center" }}>Venda x Faturamento</div>
                                        <div style={{ fontWeight: "bold", fontSize: "14px", textAlign: "right" }} >                                       
                                            <div style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }}>  Média Diária                                                                                              
                                                <div style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} className="legenda-dash">
                                                    <hr className="legenda-dash-1" />Faturado: {toDecimal(Math.round(this.state.mediaFaturamentoDia))}
                                                </div>
                                                <div style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} className="legenda-dash">
                                                    <hr className="legenda-dash-2" />Vendido: {toDecimal(Math.round(this.state.mediaVendaDia))}
                                                </div>
                                            </div>
                                        </div>

                                        <ResponsiveContainer height={Math.round(this.state.windowSize /1.3)} width="100%">
                                            <LineChart data={this.state.grafico} margin={{ top: 1, right: 1, left: 30, bottom: 5 }}>
                                            <CartesianGrid stroke="#ccc" strokeDasharray="4 4" />
                                                <ReferenceLine y={this.state.mediaVendaDia} stroke="red" strokeWidth={2} strokeDasharray="4 4" />
                                                <ReferenceLine y={this.state.mediaFaturamentoDia} strokeWidth={2}  strokeDasharray="4 4"  stroke="green" />        

                                                <Legend verticalAlign="bottom" align="center" height={30} iconSize={25}/>
                                                <XAxis tick={{strokeWidth: 0.9}} tickFormatter={(value) => `${moment(value).format("DD")}`} dataKey="data" />   

                                                <YAxis tickFormatter={(value) => `${toDecimal(Math.round(value))}`} tick={{strokeWidth: 0.9}} domain={[0, dataMax => (dataMax * 1.03)]}
                                                        type="number" name={"Quantidade"} unit={" m²"} tickCount={8}/>
                                                <Tooltip content={this.customTooltip} />                                            
                                                <Line type="linear" dataKey="faturado" strokeOpacity={10} unit={"m²"}  stroke="green"
                                                name={<span style={{ fontWeight: "bold", fontSize: "14px", verticalAlign:"middle", textAlign: "center" }}>Faturado</span>}  />
                                                
                                                <Line type="linear" dataKey="vendido" strokeOpacity={10} unit={"m²"}  stroke="red"
                                                name={<span style={{ fontWeight: "bold", fontSize: "14px", verticalAlign:"middle", textAlign: "center" }}>Vendido</span>}  />
                                            </LineChart>                                        
                                        </ResponsiveContainer>                                    
                                    </TZMTabPanel>                                    
                                </TZMTabView>
                            </TZMForm>
                        </TZMPanel>

                    </div>
                    <div className="ui-g-3">
                        <TZMPanel header="Budget / Forecast (m²)">
                            <TZMForm>
                                <div className="ui-g">
                                <div className="ui-graphic-label-acompanhamento"> {`Budget ` + this.capitalize(moment(this.state.mes).format("MMMM"))}  </div>
                                    {/* AQUI PARA BAIXO É O FORECAST */}
                                    <div className="ui-g-12" style={{ borderStyle: "groove" }} >
                                        <div className="ui-graphic-label forecast "> Forecast </div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(this.state.meta?.forecast)} />
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta faturar para o Forecast (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta?.forecastFaturar))}
                                        </div>
                                        <div title="Percentual do Budget faturado atingido" className="ui-g-12">
                                            <Progressbar value={this.state.meta?.percentualForecastFaturado} />
                                        </div>
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta vender para o Forecast (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta?.forecastVender))}
                                        </div>
                                        <div title="Percentual do Budget vendido atingido" className="ui-g-12" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>
                                            <Progressbar value={this.state.meta?.percentualForecastVendido} />
                                        </div>
                                        <div className="ui-g-12" style={{ textAlign: "center", fontSize: "15px", fontWeight: "bold" }}>
                                            Carteira Liberado + Carteira Bloqueado + Faturado
                                        </div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(Math.round(this.state.meta?.totalGeral))} />
                                    </div>

                                    {/* AQUI PARA CIMA É O FORECAST */}

                                    {/* AQUI PARA BAIXO É O BUDGET */}
                                    <div className="ui-g-12" style={{ borderStyle: "groove" }} >
                                        <div className="ui-graphic-label geral"> Budget Geral</div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(this.state.meta?.budget)} />
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta faturar para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.budgetFaturar))}
                                        </div>
                                        <div title="Percentual do Budget faturado atingido" className="ui-g-12">
                                            <Progressbar value={this.state.meta.percentualBudgetFaturado} />
                                        </div>
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta vender para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.budgetVender))}
                                        </div>
                                        <div title="Percentual do Budget vendido atingido" className="ui-g-12" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>
                                            <Progressbar value={this.state.meta.percentualBudgetVendido} />
                                        </div>
                                        <div className="ui-g-12" style={{ textAlign: "center", fontSize: "15px", fontWeight: "bold" }}>
                                            Carteira Liberado + Carteira Bloqueado + Faturado
                                        </div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(Math.round(this.state.meta.totalGeral))} />
                                    </div>
                                    {/* AQUI PARA CIMA É O BUDGET */}
                                    {/* AQUI PARA BAIXO É O BUDGET NACIONAL*/}
                                    <div className="ui-g-12" />
                                    <div className="ui-g-12" style={{ borderStyle: "groove" }} >
                                        <div className="ui-graphic-label ">  Budget Nacional </div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(this.state.meta.nacional)} />
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta faturar para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.nacionalFaturar))}
                                        </div>
                                        <div title="Percentual do Budget faturado atingido" className="ui-g-12">
                                            <Progressbar value={this.state.meta.percentualNacionalFaturado} />
                                        </div>
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta vender para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.nacionalVender))}
                                        </div>
                                        <div title="Percentual do Budget vendido atingido" className="ui-g-12" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>
                                            <Progressbar value={this.state.meta.percentualNacionalVendido} />
                                        </div>
                                        <div className="ui-g-12" style={{ textAlign: "center", fontSize: "15px", fontWeight: "bold" }}>
                                            Carteira Liberado + Carteira Bloqueado + Faturado
                                        </div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(Math.round(this.state.meta.totalNacional))} />

                                    </div>                                     
                                    {/* AQUI PARA CIMA É O BUDGET NACIONAL*/}
                                    {/* AQUI PARA BAIXO É O BUDGET INTERCOMPANY*/}
                                    <div className="ui-g-12" />
                                    <div className="ui-g-12" style={{ borderStyle: "groove" }} >
                                        <div className="ui-graphic-label intercompany "> Budget Intercompany</div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(this.state.meta.intercompany)} />
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta faturar para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.intercompanyFaturar))}
                                        </div>
                                        <div title="Percentual do Budget faturado atingido" className="ui-g-12">
                                            <Progressbar value={this.state.meta.percentualIntercompanyFaturado} />
                                        </div>
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta vender para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.intercompanyVender))}
                                        </div>
                                        <div title="Percentual do Budget vendido atingido" className="ui-g-12" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>
                                            <Progressbar value={this.state.meta.percentualIntercompanyVendido} />
                                        </div>
                                        <div className="ui-g-12" style={{ textAlign: "center", fontSize: "15px", fontWeight: "bold" }}>
                                            Carteira Liberado + Carteira Bloqueado + Faturado
                                        </div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(Math.round(this.state.meta.totalIntercompany))} />

                                    </div>
                                    {/* AQUI PARA CIMA É O BUDGET INTERCOMPANY*/}

                                    {/* AQUI PARA BAIXO É O BUDGET EXPORTACAO*/}
                                    <div className="ui-g-12" />
                                    <div className="ui-g-12" style={{ borderStyle: "groove" }} >
                                        <div className="ui-graphic-label exportacao"> Budget Exportação</div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(this.state.meta.exportacao)} />
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta faturar para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.exportacaoFaturar))}
                                        </div>
                                        <div title="Percentual do Budget faturado atingido" className="ui-g-12">
                                            <Progressbar value={this.state.meta.percentualExportacaoFaturado} />
                                        </div>
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta vender para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.exportacaoVender))}
                                        </div>
                                        <div title="Percentual do Budget vendido atingido" className="ui-g-12" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>
                                            <Progressbar value={this.state.meta.percentualExportacaoVendido} />
                                        </div>
                                        <div className="ui-g-12" style={{ textAlign: "center", fontSize: "15px", fontWeight: "bold" }}>
                                            Carteira Liberado + Carteira Bloqueado + Faturado
                                        </div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(Math.round(this.state.meta.totalExportacao))} />

                                    </div>
                                    {/* AQUI PARA CIMA É O BUDGET EXPORTACAO*/}
                                    {/* AQUI PARA BAIXO É O BUDGET OFFICE PIMACO*/}
                                    <div className="ui-g-12" />
                                    <div className="ui-g-12" style={{ borderStyle: "groove" }} >
                                        <div className="ui-graphic-label pimaco"> Budget Office / Pimaco </div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(this.state.meta.officePimaco)} />
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta faturar para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.officePimacoFaturar))}
                                        </div>
                                        <div title="Percentual do Budget faturado atingido" className="ui-g-12">
                                            <Progressbar value={this.state.meta.percentualOfficePimacoFaturado} />
                                        </div>
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta vender para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.officePimacoVender))}
                                        </div>
                                        <div title="Percentual do Budget vendido atingido" className="ui-g-12" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>
                                            <Progressbar value={this.state.meta.percentualOfficePimacoVendido} />
                                        </div>
                                        <div className="ui-g-12" style={{ textAlign: "center", fontSize: "15px", fontWeight: "bold" }}>
                                            Carteira Liberado + Carteira Bloqueado + Faturado
                                        </div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(Math.round(this.state.meta.totalOfficePimaco))} />

                                    </div>
                                    {/* AQUI PARA CIMA É O BUDGET OFFICE PIMACO*/}                                          
                                </div>
                            </TZMForm>
                        </TZMPanel>
                    </div>             
                    
                </div>
                <span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
                <ModalRealm />
            </TZMMainContent>
        )
    }
}
