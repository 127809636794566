import moment from 'moment';
import React from 'react';
import { toCurrency, toDecimal } from '../../../components/common/TZMFormatter';
import TZMDialog from '../../../components/dialog/TZMDialog';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { ColumnGroup } from 'primereact/components/columngroup/ColumnGroup';
import { Row } from 'primereact/components/row/Row';
import { Column } from 'primereact/components/column/Column';
import TZMPanelFooter from '../../../components/panel/TZMPanelFooter';
import TZMButton from '../../../components/common/TZMButton';
import TZMShortcut from '../../../utilities/TZMShortcut';

export class PainelPCPDetalheProduzidoProduzir extends React.Component {

    constructor(props) {
        super(props);        
        moment.locale('pt-BR');
        this.state = {
            visible: true,
            listDetalhes: props.listDetalhes,
            status: props.status,
            header: props.header
        };
    }

    fechar = () => {
        this.setState({ visible: false });
    }

    mesFormat(rowData, column) {
        return moment(rowData[column.field]).format("DD/MM/YYYY");
    }

    currencyFormat(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toCurrency(rowData[column.field])}</div>;
    }
    
    toDecimal(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toDecimal(Math.round(rowData[column.field]))}</div>
    }

    pedido(rowData, column) {
        return <div style={{ textAlign: "left" }}>{`${rowData.pedidoId} -  ${rowData.itemId}`}</div>
    }

 
    render() {
        return (
            <TZMDialog style={{ width: "99%" }} visible={this.state.visible} modal header={this.state.header} {...this.props} 
            autoAlign={true} responsive={true}>
                <div className="ui-g">
                    <DataTable emptyMessage="Nenhum registro encontrado" ref={(el) => this.dt = el}  autoLayout
                     value={this.state.listDetalhes}
                        paginator={true} rows={20} pageLinkSize={10} footerColumnGroup={
                            <ColumnGroup>
                                <Row>
                                    <Column colSpan={3} style={{ fontWeight: "bold", fontSize: "12px", textAlign: "left" }} footer={`Total de Registros: ` + this.state.listDetalhes.length} />                                    
                                    <Column colSpan={10}/>                                    
                                </Row>
                            </ColumnGroup>
                        }>
                        <Column style={{ width: "8em", textAlign: "center",   fontSize: "12px" }} sortable field="clienteId" key="clienteId" header="Cliente" filter={true} />
                        <Column style={{ width: "*", textAlign: "left",   fontSize: "12px" }} sortable field="clienteRazaoSocial" key="clienteRazaoSocial" header="Razão Social" filter={true} />
                        <Column style={{ width: "8em", textAlign: "center",   fontSize: "12px" }} sortable field="pedidoId" key="pedidoId" header="Pedido" filter={true} body={this.pedido}/>                        
                        <Column style={{ width: "4em", textAlign: "center", fontSize: "12px" }} sortable field="tipoMovimentoId" key="tipoMovimentoId" header="TM" filter={true} />    
                        <Column style={{ width: "6.5em", textAlign: "center",   fontSize: "12px" }} field="dataInclusao" key="dataInclusao" header="Data Inclusão" sort body={this.mesFormat} />
                        <Column style={{ width: "6.5em", textAlign: "center",   fontSize: "12px" }} field="dataEntrega" key="dataEntrega" header="Data Entrega" sort body={this.mesFormat} />
                        <Column style={{ width: "5.5em", textAlign: "center",   fontSize: "12px" }} sortable field="m2" key="m2" body={this.toDecimal} header="Vendido" />
                        <Column style={{ width: "5.5em", textAlign: "center",   fontSize: "12px" }} sortable field="produzido" key="produzido" body={this.toDecimal} header="Produzido" />
                        <Column style={{ width: "5.5em", textAlign: "center",   fontSize: "12px" }} sortable field="produzir" key="produzir" body={this.toDecimal} header="Produzir" />                        
                        <Column style={{ width: "3.5em", textAlign: "center", fontSize: "12px" }} sortable field="unidade" key="unidade" header="UN" filter={true} />
                        <Column style={{ width: "9em", textAlign: "center",   fontSize: "12px" }} sortable field="produtoId" key="produtoId" header="Cod. Produto" filter={true} />
                        <Column style={{ width: "*", textAlign: "center",   fontSize: "12px" }} sortable field="produtoDescricao" key="produtoDescricao" header="Produto" filter={true} />
                        <Column style={{ width: "6em", textAlign: "center",   fontSize: "12px" }} sortable field="mercado" key="mercado" header="Mercado" filter={true} />

                    </DataTable>
                </div>
                <TZMPanelFooter>
                    <TZMButton className="ui-button-secondary"  label="Fechar" icon="fas fa-close" onClick={this.fechar} />
                </TZMPanelFooter>
                <TZMShortcut active={this.state.visible} onEscape={this.fechar} />
            </TZMDialog>
        );
    }
}